<template>
    <div>
        <competitionLevelList :sport_id="sport_id"></competitionLevelList>
    </div>
</template>

<script>

import competitionLevelList from '@/components/ballsports/competition_level/list.vue';

export default {
    components: {
        competitionLevelList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 10,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>