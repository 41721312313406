<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛事: ">
                    <Row>
                        <Col span="20" >
                            <uniqueTournamentSearch v-model="data.unique_tournament" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'unique_tournament_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'unique_tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="competition_level_id" :type="type" :sub_type="1" _key="unique_tournament_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                

                <FormItem label="等级: ">
                    <Row>
                        <Col span="20" >
                            <Input type="number" v-model="data.level" :class="locks[String(type)+'-1-'+'level'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'level']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="competition_level_id" :type="type" :sub_type="1" _key="level"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="权重: ">
                    <Row>
                        <Col span="20" >
                            <Input type="number" v-model="data.weight" :class="locks[String(type)+'-1-'+'weight'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'weight']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="competition_level_id" :type="type" :sub_type="1" _key="weight"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="competition_level_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import uniqueTournamentSearch from '../unique_tournament/search.vue';
import Lock from '../lock.vue';
import { getCompetitionLevel, updateCompetitionLevel} from '@/api/ballsports/base/competition_level';

export default {
    name: "CompetitionLevelEdit",
    props: {
        sport_id: Number,   // 运动id
        competition_level_id: Number,   // 联赛id
    },
    components: {
        Lock,
        uniqueTournamentSearch,
        // venueTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 7,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                unique_tournament: {},
                level: 0,
                weight: 0,
                deleted: 0,
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.competition_level_id && self.competition_level_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.competition_level_id,
                }
                getCompetitionLevel( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // self.locks = response.data.data.locks ;
                            self.locks = {}
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.locks = {}
                self.data = {
                    id: 0,
                    sport_id: self.sport_id,
                    unique_tournament: {},
                    level: 0,
                    weight: 0,
                    deleted: 0,
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;

            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                competition_id: self.data.unique_tournament.id,
                level: self.data.level,
                weight: self.data.weight,
                deleted: self.data.deleted,
            }
            // console.log(params)
            updateCompetitionLevel(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "competition_level_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>